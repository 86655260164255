import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

import Layout from '../components/Layout/Layout';
import useImageQuery from '../utils/use-image-query';
import styles from './security.module.scss';

import srcIconCheckedHappyGreen from '../assets/icon-checked-happy-green.svg';
import srcIconCheckedVeryLightBlue from '../assets/icon-checked-very-light-blue.svg';
import srcLogoWhite from '../assets/logo-white.svg';
import {
  default as srcIconInitiative1,
  default as srcIconInitiative2,
} from '../assets/security/icon-security-initiative-1.svg';
import srcIconInitiative3 from '../assets/security/icon-security-initiative-3.svg';
import srcIconInitiative4 from '../assets/security/icon-security-initiative-4.svg';
import srcIconInitiative5 from '../assets/security/icon-security-initiative-5.svg';
import srcIconInitiative6 from '../assets/security/icon-security-initiative-6.svg';
import srcIconInitiative7 from '../assets/security/icon-security-initiative-7.svg';
import srcIllustrationPowerDevice from '../assets/security/illustration-power-device.png';
import srcIllustrationSecurityPhone from '../assets/security/illustration-security-phone.svg';
import srcIllustrationShield from '../assets/security/illustration-shield.svg';
import { StripType } from '../types';
import useIsSSR from '../utils/use-is-ssr';

interface Props {
  data: {
    contentful: {
      stripCollection: { items: StripType[] };
    };
  };
}

const HeroBG = React.lazy(() => import('../components/SecurityPage/HeroBG'));

export const securityPageQuery = graphql`
  query SecurityPageQuery($language: String!, $dev: Boolean!) {
    contentful {
      stripCollection(
        order: sys_publishedAt_DESC
        limit: 10
        where: { domains_contains_some: "smartpay.co", active: true }
        preview: $dev
      ) {
        items {
          sys {
            id
          }
          title(locale: $language)
          link(locale: $language)
          type
          active(locale: $language)
          domains
          paths
          startsFrom
          endsOn
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const CheckedHappyGreen = () => (
  <img src={srcIconCheckedHappyGreen} width="20" height="30" alt="" />
);

const CheckedVeryLightBlue = () => (
  <img src={srcIconCheckedVeryLightBlue} width="20" height="30" alt="" />
);

const SecurityPage = ({ data }: Props) => {
  const isSSR = useIsSSR();
  const { t, originalPath, i18n } = useI18next();
  const { srcSecurityHero } = useImageQuery();
  const {
    contentful: {
      stripCollection: { items: strips },
    },
  } = data;

  return (
    <Layout
      t={t}
      originalPath={originalPath}
      i18n={i18n}
      rootClassName={styles['root-wrapper']}
      strips={strips}
    >
      <section className={styles.hero}>
        {!isSSR && (
          <React.Suspense fallback={null}>
            <HeroBG />
          </React.Suspense>
        )}
        <div className={styles.wrapper}>
          <img src={srcLogoWhite} alt="" width="68" className={styles.logo} />
          <h1>{t('security-page-title')}</h1>
          <h2>{t('security-page-subtitle')}</h2>
          <img
            src={srcIllustrationShield}
            alt=""
            width="200"
            className={styles.illustration}
          />
        </div>
      </section>
      <section className={styles.introContainer}>
        <div className={styles.intro}>
          <div className={styles.box}>
            <div className={styles.content}>
              <img
                src={srcIllustrationSecurityPhone}
                alt=""
                width="200"
                className={styles.illustration}
              />
              <div>
                <h2>{t('security-page-intro-title')}</h2>
                <p>{t('security-page-intro-desc')}</p>
              </div>
            </div>
            <GatsbyImage
              image={srcSecurityHero.childImageSharp.gatsbyImageData}
              alt=""
              className={styles.photo}
            />
          </div>
        </div>
      </section>
      <section className={styles.initiativesContainer}>
        <div className={styles.initiatives}>
          <div className={styles.box}>
            <h2>{t('security-page-initiatives-title')}</h2>
            <ul>
              <li>
                <img src={srcIconInitiative1} width="24" height="32" alt="" />
                <h3>{t('security-page-initiative-1-title')}</h3>
                <p>{t('security-page-initiative-1-desc')}</p>
              </li>
              <li>
                <img src={srcIconInitiative2} width="24" height="32" alt="" />
                <h3>{t('security-page-initiative-2-title')}</h3>
                <p>{t('security-page-initiative-2-desc')}</p>
              </li>
              <li>
                <img src={srcIconInitiative3} width="24" height="32" alt="" />
                <h3>{t('security-page-initiative-3-title')}</h3>
                <p>{t('security-page-initiative-3-desc')}</p>
              </li>
              <li>
                <img src={srcIconInitiative4} width="24" height="32" alt="" />
                <h3>{t('security-page-initiative-4-title')}</h3>
                <p>{t('security-page-initiative-4-desc')}</p>
              </li>
              <li>
                <img src={srcIconInitiative5} width="24" height="32" alt="" />
                <h3>{t('security-page-initiative-5-title')}</h3>
                <p>{t('security-page-initiative-5-desc')}</p>
              </li>
              <li>
                <img src={srcIconInitiative6} width="24" height="32" alt="" />
                <h3>{t('security-page-initiative-6-title')}</h3>
                <p>{t('security-page-initiative-6-desc')}</p>
              </li>
              <li>
                <img src={srcIconInitiative7} width="24" height="32" alt="" />
                <h3>{t('security-page-initiative-7-title')}</h3>
                <p>
                  <Trans i18nKey="security-page-initiative-7-desc" />
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className={styles.saftyRecommendationContainer}>
        <div className={styles.saftyRecommendation}>
          <div className={styles.box}>
            <img
              src={srcIllustrationPowerDevice}
              alt=""
              width="244"
              className={styles.illustration}
            />
            <div>
              <h2>{t('security-page-safty-recommendation-title')}</h2>
              <ul>
                <li>
                  <CheckedHappyGreen />
                  {t('security-page-safty-recommendation-item-1')}
                </li>
                <li>
                  <CheckedHappyGreen />
                  {t('security-page-safty-recommendation-item-2')}
                </li>
                <li>
                  <CheckedHappyGreen />
                  {t('security-page-safty-recommendation-item-3')}
                </li>
                <li>
                  <CheckedHappyGreen />
                  {t('security-page-safty-recommendation-item-4')}
                </li>
                <li>
                  <CheckedHappyGreen />
                  {t('security-page-safty-recommendation-item-5')}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.emailRecommendationContainer}>
        <div className={styles.emailRecommendation}>
          <div className={styles.box}>
            <h2>{t('security-page-email-recommendation-title')}</h2>
            <ul>
              <li>
                <CheckedVeryLightBlue />
                {t('security-page-email-recommendation-item-1')}
              </li>
              <li>
                <CheckedVeryLightBlue />
                <Trans
                  i18nKey="security-page-email-recommendation-item-2"
                  components={{ a: <a /> }}
                />
              </li>
              <li>
                <CheckedVeryLightBlue />
                <Trans
                  i18nKey="security-page-email-recommendation-item-3"
                  components={{ a: <a /> }}
                />
              </li>
              <li>
                <CheckedVeryLightBlue />
                {t('security-page-email-recommendation-item-4')}
              </li>
            </ul>
            <Link to="/contact-us/" className={styles.contactButton}>
              {t('contact-us-form')}
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SecurityPage;
